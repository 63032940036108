<!-- Toolbar -->
<div class="toolbar" role="banner">
  <img
    width="40"
    alt="Corona Friday Logo"
    src="assets/corona-virus-transparent-logo.png"
  />
  <span>Data Collection</span>
  <input type="text" id="banner-input" [(ngModel)]="streamId" />
    <div class="spacer"></div>
</div>

<div class="content" role="main">

  <div class="centered">
    <ng-container *ngIf="loading">
      <!-- Make a rotating and resizing corona friday logo to use as loading icon -->
      <mat-progress-spinner color="#1976d2" mode="indeterminate"></mat-progress-spinner>
    </ng-container>
    <ng-container *ngIf="!loading && imageFileName">
      <img id="the-image" [src]="imageSource" />
    </ng-container>
  </div>

  <div class="centered" *ngIf="analyzeLoading">
    <div class="loading-icon-container">
      <!-- TODO: Make Each Loading Icon it's own component -->
      <!-- TODO: Look into and consider using a determinate loading icon and polling for percentage -->

      <!-- Loader 1 -->
      <svg *ngIf="!randomNumberIcon || randomNumberIcon === 1" version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
        <circle fill="none" stroke="#1976d2" stroke-width="6" stroke-miterlimit="15" stroke-dasharray="14.2472,14.2472" cx="50" cy="50" r="47" >
          <animateTransform 
             attributeName="transform" 
             attributeType="XML" 
             type="rotate"
             dur="5s" 
             from="0 50 50"
             to="360 50 50" 
             repeatCount="indefinite" />
      </circle>
      <circle fill="none" stroke="#1976d2" stroke-width="1" stroke-miterlimit="10" stroke-dasharray="10,10" cx="50" cy="50" r="39">
          <animateTransform 
             attributeName="transform" 
             attributeType="XML" 
             type="rotate"
             dur="5s" 
             from="0 50 50"
             to="-360 50 50" 
             repeatCount="indefinite" />
      </circle>
      <g fill="#1976d2">
      <rect x="30" y="35" width="5" height="30">
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5" 
           repeatCount="indefinite" 
           begin="0.1"/>
      </rect>
      <rect x="40" y="35" width="5" height="30" >
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5" 
           repeatCount="indefinite" 
           begin="0.2"/>
      </rect>
      <rect x="50" y="35" width="5" height="30" >
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5" 
           repeatCount="indefinite" 
           begin="0.3"/>
      </rect>
      <rect x="60" y="35" width="5" height="30" >
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5"  
           repeatCount="indefinite" 
           begin="0.4"/>
      </rect>
      <rect x="70" y="35" width="5" height="30" >
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5" 
           repeatCount="indefinite" 
           begin="0.5"/>
      </rect>
      </g>
    </svg>

    <!-- Loader 2 -->
    <svg *ngIf="!randomNumberIcon || randomNumberIcon === 2" version="1.1" id="L6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
   <rect fill="none" stroke="#1976d2" stroke-width="4" x="25" y="25" width="50" height="50">
  <animateTransform
     attributeName="transform"
     dur="0.5s"
     from="0 50 50"
     to="180 50 50"
     type="rotate"
     id="strokeBox"
     attributeType="XML"
     begin="rectBox.end"/>
  </rect>
   <rect x="27" y="27" fill="#1976d2" width="46" height="50">
  <animate
     attributeName="height"
     dur="1.3s"
     attributeType="XML"
     from="50" 
     to="0"
     id="rectBox" 
     fill="freeze"
     begin="0s;strokeBox.end"/>
  </rect>
</svg>

<!-- Loader 3 -->
<svg *ngIf="!randomNumberIcon || randomNumberIcon === 3" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
 <path fill="#1976d2" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="2s" 
         from="0 50 50"
         to="360 50 50" 
         repeatCount="indefinite" />
  </path>
 <path fill="#1976d2" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
  c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="1s" 
         from="0 50 50"
         to="-360 50 50" 
         repeatCount="indefinite" />
  </path>
 <path fill="#1976d2" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
  L82,35.7z">
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="2s" 
         from="0 50 50"
         to="360 50 50" 
         repeatCount="indefinite" />
  </path>
</svg>

<!-- Loader 4 -->
<svg *ngIf="!randomNumberIcon || randomNumberIcon === 4" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
<rect fill="#1976d2" width="3" height="100" transform="translate(0) rotate(180 3 50)">
  <animate
      attributeName="height"
      attributeType="XML"
      dur="1s"
      values="30; 100; 30"
      repeatCount="indefinite"/>
</rect>
<rect x="17" fill="#1976d2" width="3" height="100" transform="translate(0) rotate(180 20 50)">
  <animate
      attributeName="height"
      attributeType="XML"
      dur="1s"
      values="30; 100; 30"
      repeatCount="indefinite"
      begin="0.1s"/>
</rect>
<rect x="40" fill="#1976d2" width="3" height="100" transform="translate(0) rotate(180 40 50)">
  <animate
      attributeName="height"
      attributeType="XML"
      dur="1s"
      values="30; 100; 30"
      repeatCount="indefinite"
      begin="0.3s"/>
</rect>
<rect x="60" fill="#1976d2" width="3" height="100" transform="translate(0) rotate(180 58 50)">
  <animate
      attributeName="height"
      attributeType="XML"
      dur="1s"
      values="30; 100; 30"
      repeatCount="indefinite"
      begin="0.5s"/>
</rect>
<rect x="80" fill="#1976d2" width="3" height="100" transform="translate(0) rotate(180 76 50)">
  <animate
      attributeName="height"
      attributeType="XML"
      dur="1s"
      values="30; 100; 30"
      repeatCount="indefinite"
      begin="0.1s"/>
</rect>
</svg>
    </div>
  </div>

  <div class="centered" *ngIf="analyzeLoading">
    <div id="analyzing-info" class="info-text">
      Analyzing image. This may take a while.
    </div>
  </div>

  <div class="centered" *ngIf="knownGame && !analyzeLoading">
    <div id="image-info" class="info-text">
      Looks like you played {{ gamePlayed }}
    </div>
  </div>

  <div class="centered" *ngIf="winner && !analyzeLoading">
    <div id="image-winner-info" class="info-text">
      I think you won! Congratulations! 🥇🥇🥇
    </div>
  </div>

  <div class="centered" *ngIf="unknownAnalysis && !analyzeLoading">
    <div id="image-winner-info" class="info-text">
      Unable to determine much. This image has been flagged for further analysis.
    </div>
  </div>
  
  <div class="centered">
    <button id="the-button" (click)="handleButtonClick()">
      {{ buttonText }}
    </button>
  </div>

  <div class="centered" *ngIf="error">
    <div class="error-text">
      {{ errorText }}
    </div>
  </div>
  

</div>